@import 'vars.scss';

.backdrop {
  background-color: rgba(0, 0, 0, 0.74);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 52;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  animation: fade 100ms ease-in;
}

.container {
  color: #000;
  border-radius: 50%;

  .closeIcon {
    position: absolute;
    top: 40px;
    right: 30px;
    z-index: 45;
  }

  .modalBody {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    flex: 1 1 auto;
    padding: 1rem;
    margin-top: 50px;

    button {
      margin-bottom: 20px;
      text-transform: uppercase;
      max-width: 100%;
      height: 70px;
    }

    .divider {
      font-size: 15px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &::before,
      &::after {
        flex: 1;
        content: '';
        padding: 1px;
        background-color: gray;
        margin: 20px;
      }
    }

    .magicLinkConnector {
      height: 70px;
      border: none;
      border-radius: 20px;
      appearance: none;
    }

    .otherWalletContainer {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      & > div:not(.nested-div) {
        width: 100%;
      }

      & > div > button > div {
        justify-content: center;
      }
    }

    .otherWalletBtn {
      font-size: 10px;
      width: 50px;
      height: 50px;
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  position: fixed;
  width: 75%;
  transition: transform 0.3s ease-out;
  z-index: 53;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 35px;
  background-color: #fff;
  border-radius: 0;
  font-size: 19px;
  line-height: 1.32;
  letter-spacing: 0.35px;
  text-align: justify;
  border-radius: 20px;

  @media (min-width: $breakpoint-md) {
    width: 600px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
