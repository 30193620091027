@import 'vars.scss';

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  background-color: transparent;
}

.mobileMenu {
  position: fixed;
  left: 0;
  display: flex;
  width: 283.6px;
  border: 2px solid #000;
  border-bottom: none;
  border-left: 8.8px solid #000;
  z-index: 9999;
  background-color: white;
  padding: unset;
  height: calc(100vh - 2px);

  @media (min-width: 826px) {
    display: none;
  }
}
.strongLine {
  height: 16px;
  width: auto;
  background-color: black;
}

.container {
  display: flex;
  flex-direction: column;

  @media (min-width: 826px) {
    display: flex;
    justify-content: center;
  }

}

.containerHome {
  position: relative;
  width: 100%;
  
  @media (min-width: 826px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeNav {
    align-items: flex-start;
    margin: 0 0 12px;
    padding-top: 30px;

    @media (min-width: 826px) {
      padding: 0 20px 30px;
      align-items: center;
    }
  }

  .hiddenOnMobile {
    display: none;
    @media (min-width: 826px) {
      display: flex;
    }
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 15px 0 26px;
  margin: -13px 0 0;
  max-width: 826px;

    .navLeft {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 20px;

      @media (min-width: 826px) {
        margin-right: 20px;
      }

      .hamburgerIcon {
        z-index: 1;
        display: flex;
        @media (min-width: 826px) {
          display: none;
        }
      }
      
      .link {
        display: flex;

        .logo {
          margin: 0 auto;
          max-width: 100%;
        }
      }
    }

    .homeLeftNav {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      
      .logo {
        margin-top: -7px;
      }
    }

    .desktopMenu {
      display: none;
      @media (min-width: 826px) {
        display: flex;
        width: 100%;
      }
    }

  .rightNav {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: right;
    margin-left: auto;
    flex-wrap: wrap-reverse;

    .dropdown {
      margin-left: 15px;
      .option {
        display: flex;
        align-items: center;
      }
      .optionIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
      }
      .optionLabel {
        font-size: 18px;
        padding-left: 6px;
      }
    }
    .customSignInButton {
      color: #45b8ff;
      width: 180px;
      border: 1px solid #45b8ff;
      height: 46px;
      margin: 0 0 0 15px;
      padding: 0 14px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 0;
      background-color: transparent;
    }
  }   
}
