@import 'vars';

@media (max-width: 826px) {
  .mobileContainer {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.container {
  padding: 0 3px 10px 33px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: black;
  justify-content: space-between;
  height: calc(100% - 50px);
  color: #000;
  font-style: normal;
  line-height: normal;

  @media (min-width: 826px) {
    flex-direction: row;
    margin-top: 0px;
    padding: 0;
  }

  .closeImg {
    margin: 12px 13px 0 0;

    @media (min-width: 826px) {
      display: none;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .menuItems {
      padding-top: 66px;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      font-weight: 700;
      gap: 40px;

      @media (min-width: 826px) {
        flex-direction: row;
        padding-top: 10px;
      }
    }

    .text {
      border-top: 1px solid black;
      padding-top: 35px;
      font-size: 15px;
      font-weight: 400;

      @media (min-width: 826px) {
        display: none;
        padding-top: 10px;
      }
    }
  }
}
