@import './vars';
@import './assets/fonts/iconfont.css';

:root {
  --bright-turquoise: #20f2ec;
  --dodger-blue: #45b8ff;
  --strawberry: #ff1d25;
  --black: #0e273b;
  --pale-blue: #dfe8e8;
  --light-gray-blue: rgba(0, 0, 0, 0.25);
  --white: #fff;
  --gray: #b7bec4;
  --dark-gray-blue: #263d4f;
  --light-gray: #e6e6e6;
}

html,
body,
#__next {
  height: 100%;
  background: #e2fcb0;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: auto;
  margin: 0;
}

input, 
textarea, 
button {
  font-family: var(--font-inter);
} 
#__next {
  display: flex;
  flex-direction: column;
}

button {
  padding-block: 0;
  padding-inline: 0;
}

code {
  white-space: break-spaces;
}

main {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .layout {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;

      .component {
        max-width: 425px;
        width: 100%;
      }
    }
  }
}

img {
  cursor: pointer;
}

* {
  outline: none !important;
}

h1,
h2,
h3,
h4 {
margin-block: 0;
margin-inline: 0;
}

b {
  font-weight: 600;
}

p {
  font-size: 15px;
}

#__next {
  overflow-y: auto;
}

a,
.a {
  color: var(--link-color);

  &:hover {
    color: var(--link-color) !important;
    cursor: pointer;
    text-decoration: none !important;
  }
}

::placeholder {
  color: var(--light-gray-blue);
}

.input, .textarea {
  border: none;
  width: 100%;
}

.input {
  outline: none;
  color: black;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  background-clip: padding-box;
  border: 0;
  border-radius: 0;

  &:hover {
    background-color: var(--border-color);
  }
}

.column {
  width: 160px;
}

.text-gray {
  color: var(--pale-blue) !important;
}

.bg-turq {
  background-color: var(--bright-turquoise);
}

.text-turq {
  color: var(--bright-turquoise);
}

.bg-strawberry {
  background-color: var(--strawberry) !important;
}

.text-strawberry {
  color: var(--strawberry);
}

.bg-progress-left {
  background-color: #9e3edb;
}

.markdown-body blockquote {
  color: var(--text-color);
  border-left-color: var(--text-color);
}

.circle {
  border-radius: 50%;
}

.logoLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
}
