@import 'vars.scss';

.disclaimerForm {
  color: #000;

  .modalBody {
    background-color: #fff;
    border-radius: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: start;
    overflow-y: auto;

    .points {
      margin: 5px 0px;

      ol {
        padding-inline-start: 20px;
      }
    }

    .summary {
      margin-top: 20px;

      @media (min-width: $breakpoint-md) {
        margin-top: 0px;
      }
    }
  }
}

.modalBody {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  flex: 1 1 auto;
  padding: 1rem;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.74);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  animation: fade 100ms ease-in;
}

.modal {
  height: 70vh;
  display: flex;
  align-items: center;
  position: fixed;
  width: 75%;
  transition: transform 0.3s ease-out;
  z-index: 99999;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 35px;
  background-color: #fff;
  border-radius: 0;
  font-size: 19px;
  line-height: 1.32;
  letter-spacing: 0.35px;
  text-align: justify;

  @media (min-width: 548px) {
    height: 548px;
  }

  @media (min-width: $breakpoint-md) {
    width: 600px;
  }

    .headerText {
      width: 100%;
      font-size: 17px;
      font-weight: 700;
      color: #000;
      line-height: 20.57px;
      margin-bottom: 20px;
    }

    .paragraph {
      margin-top: 25px;
      
      button {
        width: 100%;
        background-color: #C3FF62;

        @media (min-width: 300px) {
          width: 276px;
        }
      }

      
      .link {
        color: #000;

        &:hover {
          color: #19b4d4;
        }
      }
    }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
