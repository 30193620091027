@import 'vars.scss';

.button {
  width: 100%;
  border: none;
  text-transform: uppercase;
  color: black;
  background: $green;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.05px;
  padding: 10px 0;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
