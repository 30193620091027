@import 'vars.scss';

.item {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-decoration: none;

  @media (min-width: 826px) {
    margin-bottom: 25px;
    margin-right: auto;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
  }
}

.active {
  font-weight: 700;
}
